*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --color-131921:#131921;
  /* --color-131921:#1C8D29; */
  --color-3b4149: #3b4149;
  --color-febd69:#febd69;
  --color-232f3e:#232f3e;
  --color-bf4800:#bf4800;
  --color-f5f5f7:#f5f5f7;
  --color-ededed:#ededed;
  --color-777777:#777777;
  --color-1c1c1b:#1c1c1b;
}
.errors{
  color: red;
  font-size: 12px;
}
.logo {
  width: 250px;
  /* height: 80px; */
  margin-right: 10px;
  float: right;
  padding-right: 40px;
}
.section-heading{
  font-size: 26px;
  line-height: 2px;
  font-weight: 500;
  margin-bottom: 30px;
}
.card-wrapper{
  background-color:white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}

body{
  font-family: 'Rubik', sans-serif;
}
a{
  text-decoration: none;
  display:inline-block;
}
.form-control:focus{
  box-shadow: none;
  border-color: inherit;
}

.gap-10{
  gap: 10px;
}
.gap-15{
  gap: 15px;
}
.gap-30{
  gap: 30px;
}
.button{
   background-color: var(--color-232f3e); 
  background-color: white;
  color: black;
  padding:13px 33px ;
  font-size: 14px;
  border-radius: 25px;
}
.button:hover{
  background-color:var(--color-bf4800) ;
  color: #131921;
}
/*vendor page*/
.v-head{
  color:var(--color-bf4800);
}
.v-icon{
  color:var(--color-bf4800);
}
.v-banner{
  background-color:#eaeaea;
}
.v-para{
  color: var(--color-bf4800);
}
.color-circle-container {
  display: flex;
}

.color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid #000; /* Optional: Add border for better visibility */
}
.size-box-container {
  display: flex;
}

.size-box {
  width: 30px; /* Adjust according to your design */
  height: 30px; /* Adjust according to your design */
  border: 1px solid #000; /* Optional: Add border for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px; /* Adjust spacing between size boxes */
}
.size-badge {
  padding: 6px 8px; 
  font-size: 14px; 
  margin-right: 1px; 
}

.v-round{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--color-bf4800);
  color: white;
  text-align: center;
  line-height: 80px;
  margin-left: 10px;
}
.v-border{
  margin-bottom: 20px;
}
.payment-para{
  line-height:2.3 ;
}
.v-wrapper{
  text-align: center;
  color: rgb(231, 130, 75);
}
.v-card{
  padding: 20px;
  background-color: white;
  width: 850px;
 margin: 30px auto;
 border-radius: 5px;
}
/* .v-card h3{
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
} */
.v-card p{
  font-size: 13px;
  color: var(--color-777777);
}
.v-card .form-control{
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}
.v-card a{
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}
.btn{
  background-color: rgb(231, 130, 75);
}
.signup{
background-color: var(--color-febd69);
color: var(--color-131921);
border: none;
}
.signup:hover{
  background-color: var(--color-131921);
 color: white;
  }
/*Header part*/

.header-top-strip{
  border-bottom: 1px solid var(--color-3b4149);
  background-color: rgb(55, 128, 65);
}

.header-upper{
  background-color:rgb(246,245,243);
}
.header-bottom{
  background-color: rgb(246,245,243);
}
.header-upper .input-group-text{
  background-color:rgb(55, 128, 65);
}
.header-upper.header-upper-links img{
  width: 35px;
  height: 35px;
}
.header-bottom .menu-link a
{
color: white;
font-size: 14px;
line-height: 18px;
font-weight: 400;
letter-spacing: 0.3;
text-transform: uppercase;
}
.header-bottom .dropdown{
  position: relative;
  display: inline-block;
}
.header-bottom .dropdown button{
  color: white;
font-size: 14px;
line-height: 18px;
font-weight: 400;
letter-spacing: 0.3;
text-transform: uppercase;
}

.cart-count{
  padding-left: 9px;
  padding-right: 9px;
}


.header-bottom .dropdown button:focus{
  box-shadow: none;
}
.header-bottom .dropdown-menu{
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(8px ,57px, 8px);
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2) !important;
  z-index: 1;
}
.header-bottom .dropdown:hover .dropdown-menu{
  display: block;
}
.header-bottom .dropdown-item{
  padding: 20px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-3b4149); 
}
.header-bottom .dropdown-item:hover{
  background-color: transparent;
  color: var(--color-febd69) !important;
}
/*header part end*/

/*Footer part*/
footer{
  background-color: var(--color-232f3e);
}
footer:not(:first-child){
  border-top: 1px solid 1px solid rgba(255,255,255,0.1);
}
footer .input-group-text{
  background-color: var(--color-232f3e);
  color: white;
  padding: 8px 17px;
  
}
/*Order */
.order-info-container {
  text-align: center;
  margin-top: 20px;
}

.order-message {
  font-size: 18px;
  color: #333;
}

.order-id {
  font-size: 24px;
  color: #007bff;
  margin-top: 10px;
}

/*footer part end*/
/*Home page start*/

.main-banner img {
  width: 100%;
  height: auto;
}
.main-banner-content{
  top: 22%;
  left: 5%;
}
.main-banner-content h4{
 font-size: 16px;
 font-weight: 400;
 line-height: 24px;
 color: var(--color-bf4800);
 margin: 0 0 12px;
 letter-spacing: 0.3px;
 text-transform: uppercase;
}

.main-banner-content h5{
  font-size: 42px;
  font-weight: 500;
  line-height: 54px;
  letter-spacing: -2px;
  text-transform: none;
}
.main-banner-content p{
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}
.small-banner-content{
  top: 25%;
  left: 10%;
}
.small-banner-content h4{
 font-size: 14px;
 font-weight: 400;
 line-height: 24px;
 color: var(--color-bf4800);
 margin: 0 0 12px;
 letter-spacing: 0.3px;
 text-transform: uppercase;
}
.small-banner-content h5{
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -2px;
  text-transform: none;
}
.small-banner-content p{
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}
/* .small-banner{
  width:49% ;
} */
.small-banner img{
  width: 100%;
  height: 40%;
}

/*Hoem wrapper 2*/

.home-wrapper{
  background-color: var(--color-f5f5f7);
 overflow-x: auto;
}
.home-wrapper-2{
  background-color: var(--color-f5f5f7);
}


/*Home wrapper 2 ends*/
/*category section*/

.categories{
  background-color:white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
.categories>div{
  width: 25%;
  padding: 10px 10px 10px 20px;
}
.categories>div>div{
  width: 60%;
}
.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3),
.categories>div:nth-child(4){
  border-bottom: 1px solid var(--color-ededed);
}
.categories>div:nth-child(1),
.categories>div:nth-child(2),
.categories>div:nth-child(3){
  border-right: 1px solid var(--color-ededed);
}
.categories>div:nth-child(5),
.categories>div:nth-child(6),
.categories>div:nth-child(7){
  border-right: 1px solid var(--color-ededed);
}
/*category section end*/
/*Home page end*/

/*Blog Part Start*/

.blog-card{
  background-color: white;
  border-radius: 10px;
}
.blog-card img{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog-content{
  padding: 15px;
}
.blog-content p.date{
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}
.blog-content h5 {
  font-size: 18px;
  color: var(--color-131921);
}

.blog-content h5 a {
  color: var(--color-131921); 
  text-decoration: none;
}

.blog-content h5 a:hover {
  color: var(--color-131921); 
  text-decoration: none;
}
.blog-content p.desc{
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}
.single-blog-card a{
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}
.single-blog-card h3{
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}
.single-blog-card p{
  color: var(--color-777777);
  font-size: 14px;
}
/*Blog Part End*/

/*Product Card Start*/

.product-card{
  padding: 15px;
  background-color: white;
  border-radius: 10px;
 /* overflow: hidden;*/
  width: 100%;
  

}

.product-card .product-image{
  /* height: 280px; */
}
/* .product-card .product-image img{
  object-fit: contain;
}
.product-card .product-image>img:last-child{
  display: none;
}
.product-card:hover .product-image>img:nth-child(1){
  display: none;
}
.product-card:hover .product-image>img:last-child{
    display: block;
}

.product-card:hover .action-bar{
  right: 15px;
} */
.product-card .product-details h6{
color: var(--color-bf4800);
font-size: 13px;
}
.product-card .product-details h5{
  font-size:15px ;
  color: var(--color-1c1c1b);

}
.product-card .product-details p.price{
  font-size: 16px;
  color: var(--color-1c1c1b);
}
.product-card p.description{
  font-size: 13px;
  color: var(--color-777777);
  margin-right: 20px;
}
.action-bar{
  top: 10%;
  right: -23%;
  transition: 0.3s;
}
.wishlist-icon {
  top: 2%;
  right: 15px;
}
/*Product card end*/
/*Special Product*/

.special-product-card{
  padding:20px 10px;
  border-radius: 10px;
  background-color: white;
}
/*Special product end*/
/*famous card*/
.famous-card .famous-content{
  top: 3%;
  left: 10%;
}
.famous-card img{
  /* width: 100%;
  height: 20%; */
  border-radius: 10px;
}
.famous-content h5{
font-size: 13px;
line-height: 20px;
font-weight: 400;
color: white;
margin-bottom: 7px;
}
.famous-content h6{
  font-size: 23px;
  line-height: 38px;
  font-weight: 500;
  color: white;

}
.famous-content p{
  font-size: 13px;
  line-height: 24px;
  font-weight: 100;
  color: white;
}
/*famous card ends*/
/*our store start*/

.filter-card{
  background-color: white;
  border-radius: 10px;
  padding: 5px 20px;
}
.filter-title{
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}
.filter-card ul, .filter-card .form-check-label{
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}
.filter-card .form-check-input:focus{
  box-shadow: none;
  border-color: rgba(0, 0,0,0.25);
}
.filter-card .form-check-input:checked{
  background-color: var(--color-febd69);
  border-color: var(--color-febd69);
}
.sub-title{
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
  margin-top: 20px;
}
.colors{
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.colors li{
  width: 20px;
  height: 20px;
  background-color: rgb(19, 148, 58);
  border-radius: 50%;
}
.random-products h5{
  font-size: 14px;
  margin-bottom: 8px;
}
.random-products:first-child{
  border-bottom: 1px solid var(--color-ededed);
}
.filter-sort-grid{
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}
.grid img{
  width: 35px;
  height: 35px;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  display: none;
}
.gr-12{
  width: 100%;
}
.gr-6{
  width: 49%;
}
.gr-4{
  width: 32%;
}
.gr-3{
  width: 24%;
}
.gr-6 .product-image{
  width: 100%;
}
.gr-6 .product-image img{
  margin: auto;
  display: block;
  width: 60%;
}
.gr-12 .product-card{
  display: flex;
  gap: 20px;
}
.gr-12 .product-card .product-image{
  width: 270px;
}
.gr-12 .product-card .product-image{
  height: auto;
}
.gr-12 .action-bar{
  top: 17%;
}

/*our store end*/
/*Contact Form Start*/

.contact-inner-wrapper{
  padding: 30px 20px;
  border-radius:15px;
  background-color:whitesmoke ;
  gap: 15px;
}
.contact-inner-wrapper > div {
  width: 48%;
}
.contact-title{
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}
.contact-inner-wrapper form .form-control{
  
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}
.contact-inner-wrapper ul{
  list-style: none;
}
.contact-inner-wrapper address, .contact-inner-wrapper a, .contact-inner-wrapper p{
  color: var(--color-777777);
}
/*Contact end*/
/*Compare Page start*/

.compare-product-card{
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;
}
.compare-product-card .cross ,.wishlist-card .cross{
  top: 10px;
  right: 10px;
  width: 20px;
  padding: 3px;
  cursor: pointer;
}
.compare-product-details h5.title, .wishlist-card h5.title{
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-1c1c1b);
}
.compare-product-details h6.price, .wishlist-card h6.price{
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}
.compare-product-card .product-detail{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px solid rgba(0,0,0,0.1);
}
/*Comapre page end*/
/*auth card start*/

.auth-card{
  padding: 20px;
  background-color: white;
  width: 400px;
 margin: 30px auto;
 border-radius: 10px;
}
.auth-card h3{
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}
.auth-card p{
  font-size: 13px;
  color: var(--color-777777);
}
.auth-card .form-control{
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}
.auth-card a{
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}
.signup{
background-color: var(--color-febd69);
color: var(--color-131921);
border: none;
}
.signup:hover{
  background-color: var(--color-131921);
 color: white;
  }
/*auth card end*/
/*Policy page start*/

.policy{
  background-color: white;
  padding: 30px;
}
/*Policy page end*/

/*Main product page*/

.description-wrapper h4, .reviews-wrapper h3{
  font-size: 26px;
  color:var(--color-1c1c1b);
  margin-bottom: 10px;
}
.description-wrapper p{
  font-size: 14px;
  color: var(--color-777777);
}
.review-inner-wrapper{
  background-color: white;
  padding: 30px;

}
.review-head{
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom: 20px;
}
.review-head h4{
  font-size: 18px;
  color: var(--color-1c1c1b);
}
.review-head p, .review-head a{
  font-size: 14px;
  color: var(--color-777777);
}
.review-form h4{
  font-size: 16px;
  color: var(--color-777777);
}
.review-form{
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.review:not(:last-child){
  border-bottom: 1 px solid rgba(0,0,0,0.1);
}
.review p{
  font-size: 14px;
  color: var(--color-777777);
}

.color-circle.selected {
  border: 2px solid green;
}

.size-badge.selected {
  border: 2px solid green;
}

.main-product-image{
  padding:20px ;
  background-color: white;
  border-radius: 10px;
}
.main-product-image > div{
  border: 1px solid rgba(0, 0,0,0.25);
  
}
.main-product-image img{
  width: 90% !important;
  height: 500px !important;
  object-fit: cover;
}
.other-product-images{
  background-color: white;
  padding: 20px;
}
.other-product-images>div{
  padding: 20px;
  width: 48%;
  border: 1px solid rgba(0, 0,0,0.25);
}
.main-product-image .js-image-zoom__zoomed-image{
  left: 40px !important;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}
.main-product-details{
  padding: 20px;
  background-color: white;
}
.border-bottom{
  border-bottom: 1px solid rgba(0, 0,0,0.25);
}
.main-product-details h3.title{
  font-size: 17px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}
.main-product-details p.price{
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
   margin-bottom: 10px;
}
.product-heading{
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
}
.product-data{
  font-size: 13px;
  columns: var(--color-777777);
  margin-bottom: 0px;
}
.main-product-details a{
  font-size: 14px;
  color: #777777;

}
.small-screen-title {
  font-size: 14px; /* Adjust font size for small screens */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflow */
}

/*Main product page end*/

/*cart page start*/
.cart-header, .cart-data{
  border-bottom: 1px solid #eaeaea;
}s
.cart-header h4{
    font-size: 13px;
    color: var(--color-777777);
}
.cart-col-1{
  width: 30%;
}
.cart-col-1 p{
  color: var(--color-777777);
  font-size: 14px;
}
.cart-col-2{
  width: 10%;
}
.cart-col-3{
  width: 15%;
}
.cart-col-4{
  width: 15%;
}
.cart-data h5.price{
  font-size: 14px;
  color: var(--color-1c1c1b);
}
/*cart page end*/

/*Shipping page start*/

.total{
  font-size: 14px;
  color: var(--color-1c1c1b);
}
.total-price{
  font-size: 16px;
  color: var(--color-777777);
}
/*Shipping page end*/
/* Disables grid layout on small screens */
@media (max-width: 767.98px) {
  .no-grid-on-sm {
    display: block;
    width: 100%;
  }
}
/* sell online*/
.sell-text {
  text-align: center;
  margin-top: 28px; /* Adjust the value as needed to move it down */
}
.sell-head {
  text-align: center;
  margin-top: 30px; /* Adjust the value as needed to move it down */
}
.icon-container {
  width: 90px; /* Adjust the size as needed */
  height: 90px;
  background-color: rgb(20, 71, 20);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px auto;
}

.icon {
  font-size: 40px; /* Adjust the size of the icons */
  color: white;
}

.icon-content-container {
  background-color: var(--color-ededed);
  padding: 20px; 
  border-radius: 5px; 
}
.i-text{
  color: rgb(20, 71, 20);
  font-weight: bold;
  font-size: medium;
}
.i-para{
  font-weight: bold;
  font-size: 20px;
}
.dropdown-menu {
  padding: 0;
  width: 290px; /* Adjust as needed */
  border: 1px solid #ddd; /* Border for the dropdown menu */
}

/* Styles for logged-in menu */
.logged-in-menu {
  background-color: #fff; /* Background color for logged-in menu */
  height: auto; /* Adjust height based on content */
}

.logged-in-menu .dropdown-item-custom {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  height: 50px;
  transition: background-color 0.3s ease;
}

.logged-in-menu .dropdown-item-custom:hover {
  background-color: #f8f9fa;
}

.logged-in-menu .dropdown-icon {
  font-size: 18px;
  margin-right: 10px; /* Add space between icon and text */
}

/* Styles for logged-out menu */
.logged-out-menu {
  background-color: #f1f1f1; /* Background color for logged-out menu */
  height: auto; /* Adjust height based on content */
}

.logged-out-menu .dropdown-item-custom {
  padding: 15px 20px;
  border-bottom: 1px solid #ccc;
}

.logged-out-menu .dropdown-item-custom:hover {
  background-color: #e0e0e0;
}

.logged-out-menu .button-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.logged-out-menu .button-text {
  margin-left: 8px;
}

.logged-out-menu .button-icon {
  font-size: 18px;
  margin-right: 8px;
}
/* Custom CSS file for NoOrderPage */
.list-group-item {
  padding: 10px 15px;
  text-decoration: none;
  color: #000; /* Default text color */
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.list-group-item:hover {
  background-color: green; /* Background color on hover */
  color: white; /* Text color on hover */
}

.icon-order {
  margin-right: 10px; /* Space between icon and text */
  font-size: 20px; /* Adjust icon size as needed */
}

.divider {
  border: none;
  border-top: 1px solid #ddd; /* Adjust color and thickness of the line */
  margin: 0;
}

.content-container {
  background-color: white; /* White background for the box */
  padding: 20px; /* Padding inside the box */
  border-radius: 8px; /* Rounded corners for the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect for depth */
}

.cart-container {
  background-color: white; /* White background for the box */
  padding: 20px; /* Padding inside the box */
  border-radius: 8px; /* Rounded corners for the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect for depth */
  margin-top: 20px; /* Space between boxes */
  text-align: center; /* Center align content */
}

.cart-icon {
  font-size: 100px; /* Increase the size of the cart icon */
  color: #b7b9b7; /* Green color for the icon */
}

.cart-container p {
  margin-top: 10px; /* Space above the text */
}

.start-shopping-link {
  display: block;
  margin-top: 10px; /* Space above the link */
  text-decoration: none;
  color: green; /* Text color for the link */
  font-weight: bold; /* Bold text */
  transition: color 0.3s; /* Smooth transition for hover effects */
}

.start-shopping-link:hover {
  color: darkgreen; /* Darker green on hover */
}

.wishlist-container {
  background-color: white; /* White background for the box */
  padding: 20px; /* Padding inside the box */
  border-radius: 8px; /* Rounded corners for the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect for depth */
  text-align: center; /* Center align content */
}

.wishlist-icon {
  font-size: 50px; /* Size of the wishlist icon */
  color: #4CAF50; /* Green color for the icon */
  width: 15%;
}

.wishlist-message {
  margin-top: 10px; /* Space above the message */
}

.wish-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.wish-card-image img {
  width: 100%;
  height: auto;
}

.wish-card .title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}

.wish-card .price {
  font-size: 14px;
  color: #333;
}

.add-to-cart-btn {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.add-to-cart-btn:hover {
  background-color: #45a049; /* Darker green on hover */
}
.cross {
  position: absolute;
  top: 10px; /* Adjust this value to move the image up */
  right: 10px; /* Adjust this value to move the image horizontally */
  width: 20px; /* Adjust the width of the image */
  height: 20px; /* Adjust the height of the image */
}

.order-card {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.order-id {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000;
}

.order-items {
  margin-top: 10px;
}

.order-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.order-title {
  font-size: 16px;
  font-weight: bold;
}

.order-price {
  color: black;
}
.view-details-btn {
  background-color: #45a049;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 90px;
}

.view-details-btn:hover {
  background-color: #45a049;
}